import { useEffect } from 'react'
import { useRouter } from 'next/router'

const IndexPage = () => {

  const {push} = useRouter()
useEffect(() => {
  push("/secure/dashboard")
})

  return (
    <></>
  )
}

export default IndexPage
